import React, {
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useContext,
  useEffect,
} from "react"
import moment from "moment"
import _get from "lodash/get"
import _filter from "lodash/filter"
import _set from "lodash/set"
import _map from "lodash/map"
import _size from "lodash/size"
import {
  areAllBookingsValid,
  calculateBookingAnounts,
  getInitialMeetingBooking,
  getMeetingRoomBookingPayload,
  getMeetingRoomCharges,
  getNewMeetingBookingPlaceholder,
  isInstantlyBookableAwfis,
  isInstantlyBookableWeWork,
  roundToNearestXXMinutes,
  isInstantlyBookableInnov8,
} from "../utils/common"
import {
  MAX_ALLOWED_BOOKINGS,
  NUMBERS,
  SPACE_BOOKING_TYPES,
  STATUS,
} from "../constants/common"
import IconLabel from "./IconLabel"
import TimeItem from "./TimeItem"
import SeatSelector from "./SeatSelector"
import DateItem from "./DateItem"
import BookingPrice from "./BookingPrice"
import TimeSlots from "./TimeSlots"

const MeetingRoomBooking = forwardRef(
  ({ spaceDetails, timeStamp, user, priceDiscount, customDate }, ref) => {
    const [bookingType, setBookingType] = useState(
      _get(getInitialMeetingBooking(timeStamp, spaceDetails), "bookingType")
    )

    const [meetingBookings, setMeetingBookings] = useState([
      getInitialMeetingBooking(timeStamp, spaceDetails),
    ])
    // console.log("MeetingRoomBooking customDate", customDate)
    const price = useMemo(
      () =>
        calculateBookingAnounts(
          user,
          spaceDetails,
          meetingBookings,
          bookingType,
          priceDiscount
        ),
      [meetingBookings, spaceDetails, user, bookingType, priceDiscount]
    )

    useImperativeHandle(
      ref,
      () => ({
        getBookingPayload() {
          const validBookings = areAllBookingsValid(
            spaceDetails,
            meetingBookings,
            bookingType
          )
          console.log({ validBookings })
          if (validBookings.status === STATUS.FAILED) {
            // toast.show(validBookings.msg);
            alert(validBookings.msg)
            return false
          }
          return getMeetingRoomBookingPayload(
            spaceDetails,
            user,
            meetingBookings,
            bookingType
          )
        },
        getPriceToCollect() {
          return price
        },
      }),
      [meetingBookings, user, spaceDetails]
    )

    const updateBookings = (index, key, value) => {
      const currentDayBookings = [...meetingBookings]
      setMeetingBookings(_set(currentDayBookings, `${index}.${key}`, value))
    }

    const onSelectDate = (index, startTime) => (_, time) => {
      const now = moment() // Current time
      const todayStart = now.startOf("day").valueOf() // Start of today
      const selectedDate = moment(time).startOf("day").valueOf() // Extract the selected date

      if (time > todayStart) {
        // Check if the `startTime` is today
        const updatedTime = moment(time)
          .hour(moment(startTime).hour())
          .minute(moment(startTime).minute())
          .valueOf()
        updateBookings(index, "bookingDate", updatedTime)
        updateBookings(index, "startTime", updatedTime)
        console.log(updatedTime, "updatedTime")
      } else {
        alert("Please select a valid date")
      }

      setDate(time)
    }
    const onSelectNextSlot = index => (_, time) => {
      // console.log("bookingStart", index)
      if (
        isInstantlyBookableAwfis(spaceDetails) ||
        isInstantlyBookableWeWork(spaceDetails) ||
        isInstantlyBookableInnov8(spaceDetails)
      ) {
        const updatedTime = roundToNearestXXMinutes(
          isInstantlyBookableAwfis(spaceDetails) ||
            isInstantlyBookableInnov8(spaceDetails)
            ? 60
            : 30
        )
        console.log("updatedTime", updatedTime)
        updateBookings(index, "startTime", updatedTime)
      } else {
        updateBookings(index, "startTime", time)
      }
    }

    const updateDuration = index => selectedDuration => {
      if (selectedDuration > 0)
        updateBookings(index, "duration", selectedDuration)
    }

    const onSelectTimeSlots = (startTime, endTime, slotLength) => {
      updateBookings(0, "startTime", startTime)
      updateBookings(0, "duration", slotLength)
    }
    // console.log("meetingBookings", meetingBookings)
    const addBooking = () => {
      setMeetingBookings([
        ...meetingBookings,
        getNewMeetingBookingPlaceholder(meetingBookings, spaceDetails),
      ])
    }

    const removeBooking = index => () => {
      setMeetingBookings(
        _filter(meetingBookings, (_, bookingIndex) => bookingIndex !== index)
      )
    }

    const updateBookingType = type => {
      setBookingType(type)
      setMeetingBookings([
        getInitialMeetingBooking(timeStamp, spaceDetails, type),
      ])
    }

    const [date, setDate] = useState(moment().startOf("day").valueOf()) // Initial example date

    const [startHour, setStartHour] = useState(9) // Initial starting hour (9 AM)
    const [endHour, setEndHour] = useState(17)

    useEffect(() => {
      if (customDate) {
        const dateString = customDate
        const day = dateString.substring(0, 2)
        const month = dateString.substring(2, 4) - 1
        const year = dateString.substring(4, 8)
        const customDateValue = new Date(year, month, day)
        const epochTime = Math.floor(customDateValue.getTime())
        setDate(epochTime)
      }
    }, [customDate])

    return (
      <>
        {/* <div
          className="mb-2"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 8,
            backgroundColor: "#F8F8F9",
            borderRadius: 4,
          }}
        >
          <p className="mb-0">Credits Needed</p>
          <p className="mb-0">{`₹ ${price}`}</p>
        </div> */}
        <BookingPrice price={price} />

        {isInstantlyBookableAwfis(spaceDetails) ||
        isInstantlyBookableInnov8(spaceDetails) ||
        isInstantlyBookableWeWork(spaceDetails) ? (
          <p className="bold" style={{ fontSize: 10 }}>
            * Space can only be booked for start of the hour. Any other time
            selected will be rounded off to nearest start hour.
          </p>
        ) : null}

        <div className="d-flex flex-row align-items-center">
          {_get(spaceDetails, "hourPassAvailable") &&
          !_get(spaceDetails, "isInstantlyBookable") ? (
            <div style={{ flex: 1 }}>
              <p className="bold" style={{ fontSize: 14 }}>
                Hourly Booking
              </p>
              <div onClick={() => updateBookingType(SPACE_BOOKING_TYPES.HOUR)}>
                <IconLabel
                  icon={"fa fa-check"}
                  iconColor={bookingType === "Hour" ? "white" : "#878787"}
                  backgroundColor={
                    bookingType === "Hour" ? "#2798B5" : undefined
                  }
                />
              </div>
            </div>
          ) : null}
          {_get(spaceDetails, "dayPassAvailable") ? (
            <div style={{ flex: 1 }}>
              <p className="bold" style={{ fontSize: 14 }}>
                Day Booking
              </p>
              <div onClick={() => updateBookingType(SPACE_BOOKING_TYPES.DAY)}>
                <IconLabel
                  icon={"fa fa-check"}
                  iconColor={bookingType === "Day" ? "white" : "#878787"}
                  backgroundColor={
                    bookingType === "Day" ? "#2798B5" : undefined
                  }
                />
              </div>
            </div>
          ) : null}
        </div>

        {_map(
          meetingBookings,
          ({ bookingDate, startTime, noOfSeats, duration }, index) => (
            <React.Fragment key={index}>
              {_size(meetingBookings) > 1 ? (
                <div className="d-flex flex-row align-items-center justify-content-between mt-3">
                  <p className="mb-0" style={{ color: "#878787" }}>
                    {NUMBERS[index]}&nbsp; Booking
                  </p>
                  <p
                    className="mb-0"
                    style={{
                      textDecorationLine: "underline",
                      color: "#878787",
                    }}
                    onClick={removeBooking(index)}
                  >
                    Cancel
                  </p>
                </div>
              ) : null}
              <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                <div>
                  <p className="bold" style={{ fontSize: 14 }}>
                    Date
                  </p>
                  <DateItem
                    date={new Date(Number(date ? date : bookingDate))}
                    onSelect={onSelectDate(index, startTime)}
                  />
                </div>
              </div>
              {_get(spaceDetails, "isInstantlyBookable") ? (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                    <p className="bold" style={{ fontSize: 14 }}>
                      {_get(spaceDetails, "isInstantlyBookable")
                        ? "Available Time Slots"
                        : "Select Time Slots"}
                    </p>
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center  ">
                    <TimeSlots
                      date={date ? date : bookingDate}
                      startHour={startHour}
                      endHour={endHour}
                      spaceDetail={_get(spaceDetails, "spaceId")}
                      onSelect={onSelectTimeSlots}
                    />
                  </div>
                </>
              ) : (
                <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                  <div>
                    <p className="bold" style={{ fontSize: 14 }}>
                      Next Slot
                    </p>
                    <TimeItem
                      hourInterval={
                        isInstantlyBookableAwfis(spaceDetails) ||
                        isInstantlyBookableInnov8(spaceDetails)
                      }
                      date={new Date(Number(startTime))}
                      onSelect={onSelectNextSlot(index)}
                    />
                  </div>
                  {bookingType === "Hour" ? (
                    <div>
                      <p className="bold" style={{ fontSize: 14 }}>
                        Duration
                      </p>
                      <SeatSelector
                        seats={duration}
                        setSeats={updateDuration(index)}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </React.Fragment>
          )
        )}
        {/* {_size(meetingBookings) < MAX_ALLOWED_BOOKINGS ? (
          <p
            className="bold mt-3 fs-6"
            style={{
              textDecorationLine: "underline",
              color: "#878787",
              cursor: "pointer",
            }}
            role="button"
            tabIndex={0}
            onClick={addBooking}
          >
            {_size(meetingBookings) < 2 ? "Book multiple dates" : "Add more"}
          </p>
        ) : null} */}
      </>
    )
  }
)

export default MeetingRoomBooking
